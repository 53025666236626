import { phoneNumberFormatter } from '@widgets-helpers';

const showSuccessScreen = (phone: string) => {
  const contentContainer = document.getElementById('contentContainer');
  const successContainer = document.getElementById('successContainer');
  const successText = document.getElementById('successMessage') as HTMLParagraphElement;

  contentContainer?.classList.add('!hidden');
  successContainer?.classList.remove('!hidden');

  successText.innerHTML = `We sent you the link to download the Wonolo app at <span class='text-brand03'>${phone}</span>.`;
};

const attachEventListeners = () => {
  const submitButton = document.getElementById('textMeButton') as HTMLButtonElement;
  const mobileInput = document.getElementById('mobileInput') as HTMLInputElement;
  const userFeedbackContainer = document.getElementById('userFeedbackContainer');

  const isSubmitDisabled = () => {
    if (mobileInput.value.replace(/\D/g, '').length === 10 && submitButton) {
      submitButton.disabled = false;
      return false;
    } else {
      submitButton.disabled = true;
      userFeedbackContainer?.classList.add('hidden');
      return true;
    }
  };

  const sendNumber = async () => {
    /* ga_client_id will grab the site's _GA user ID see: https://www.owox.com/blog/use-cases/google-analytics-client-id/#how-find */
    const data = await fetch(`https://app.wonolo.com/wonoloers/send_app_link.json`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone: mobileInput.value.replace(/\D/g, ''),
        utm_campaign: window.location.href,
        utm_medium: 'wonolo_widgets',
        // ga_client_id: getCookieName('_ga'), // same issue here about looker stuff
      }),
    })
      .then((res) => res.json())
      .catch((err) => {
        console.error(err);
        if (userFeedbackContainer) {
          userFeedbackContainer.innerText = 'There was a problem. Please, try again.';
          userFeedbackContainer.classList.remove('hidden');
        }
      });
    if (data.success === false) {
      console.error(data);
      if (userFeedbackContainer) {
        userFeedbackContainer.innerText = data.error;
        userFeedbackContainer.classList.remove('hidden');
      }
      return;
    }
    if (data.success === true) {
      if (userFeedbackContainer) {
        showSuccessScreen(mobileInput.value);
      }
      return;
    }
    if (!data.success) {
      if (userFeedbackContainer) {
        userFeedbackContainer.innerText = 'Something went wrong. Please, contact us at support@wonolo.com.';
        userFeedbackContainer.classList.remove('hidden');
      }
    }
  };

  if (mobileInput) {
    mobileInput.oninput = () => {
      isSubmitDisabled();
      mobileInput.value = phoneNumberFormatter(mobileInput.value);
    };
    mobileInput.addEventListener('keyup', (e) => {
      if (e.key === 'Enter' && !isSubmitDisabled()) {
        sendNumber();
      }
    });
  }

  submitButton?.addEventListener('click', async () => {
    await sendNumber();
  });
};

const attachModalEventListeners = () => {
  const closeButton = document.getElementById('modalCloseButton');
  const startWonoloingButton = document.getElementById('startWonoloingButton');
  const overlay = document.getElementById('modalOverlay');
  const modal = document.getElementById('modalMobileIntake');

  const showModalElements = () => {
    modal?.classList.remove('hidden');
    overlay?.classList.remove('hidden');
  };

  const hideModalElements = () => {
    modal?.classList.add('hidden');
    overlay?.classList.add('hidden');
  };

  closeButton?.addEventListener('click', () => {
    hideModalElements();
  });

  overlay?.addEventListener('click', () => {
    hideModalElements();
  });

  startWonoloingButton?.addEventListener('click', () => {
    showModalElements();
  });
};

export { attachEventListeners, attachModalEventListeners };
