require('@widgets-styles/tw-import.css');

const LeadGenBox = document.getElementById('lead-gen-box');

export const generateLeadGenBox = () => {
  console.log('run functions', LeadGenBox);
};

const script = document.getElementById('lead-gen-box-bundle');

if (script !== null) {
  script.onload = () => {
    generateLeadGenBox();
  };
}
