import { generateMobileIntake } from '@widgets-components';
import { attachModalEventListeners } from '@widgets-ui';
import { CLOSE_X_IMAGE } from '@widgets-helpers';

export const generateTextMeModal = () => {
  const container = document.getElementById('mobile-intake-modal');
  const modal = document.createElement('div');
  const content = document.createElement('div');
  const overlay = document.createElement('div');
  const closeButton = generateCloseModalButton();
  overlay.setAttribute('id', 'modalOverlay');
  modal.setAttribute('id', 'modalMobileIntake');
  content.setAttribute('id', 'text-me-box');

  overlay.classList.add('hidden');
  modal.classList.add('hidden');

  modal.appendChild(closeButton);
  modal.appendChild(content);
  if (container) {
    container.appendChild(overlay);
  }
  return modal;
};

export const generateCloseModalButton = () => {
  const closeButton = document.createElement('button');
  const XImage = document.createElement('img');

  XImage.src = CLOSE_X_IMAGE;

  closeButton.setAttribute('id', 'modalCloseButton');
  closeButton.appendChild(XImage);

  return closeButton;
};

export const generateStartWonoloingButton = () => {
  const startWonoloingButton = document.createElement('button');
  startWonoloingButton.setAttribute('id', 'startWonoloingButton');
  startWonoloingButton.innerText = 'Start Wonoloing';
  return startWonoloingButton;
};

export const generateModalSystem = () => {
  require('@widgets-styles/TextMeLink/TextMeLinkModal.css');
  const mobileIntake = document.getElementById('mobile-intake-modal');
  const startButton = generateStartWonoloingButton();
  const textMeModal = generateTextMeModal();
  mobileIntake?.appendChild(textMeModal);
  mobileIntake?.appendChild(startButton);
  attachModalEventListeners();
  generateMobileIntake();
  return mobileIntake;
};

const script = document.getElementById('mobile-intake-modal-bundle');

if (script !== null) {
  script.onload = () => {
    generateModalSystem();
  };
}
