import { generateLocationEmptyNote } from '@widgets-components';
import {
  VALID_CATEGORIES,
  CATEGORIES_TITLE,
  GREY_COLOR,
  TEAL_COLOR,
  NO_MATCH_TEXT,
  SEO_APP_DOMAIN,
} from '@widgets-helpers';
import { debounce } from '@widgets-helpers';
import { TGeocodeResult, TAddressComponent } from '@widgets-types';
import { generateGetCurrentLocationBtn } from '@widgets-components';

const SearchBox = document.getElementById('search-box');

let DOMAIN = SEO_APP_DOMAIN;
if (SearchBox?.dataset?.env && SearchBox?.dataset?.url) {
  DOMAIN = SearchBox.dataset.url;
}

const toggleMarkerTitle = (inputAbility: boolean) => {
  const locationMarkerTitle = document.getElementById('locationMarkerTitle') as HTMLElement;
  inputAbility
    ? (locationMarkerTitle.innerHTML = 'Disable Location Services')
    : (locationMarkerTitle.innerHTML = 'Enable Location Services');
};

const toggleMarkerColor = () => {
  const selectedPath = document.getElementById('markerPathTwo');
  if (selectedPath?.getAttribute('fill') === TEAL_COLOR) {
    selectedPath?.setAttribute('fill', GREY_COLOR);
  } else {
    selectedPath?.setAttribute('fill', TEAL_COLOR);
  }
};

const toggleLocatedAttr = (elem: HTMLInputElement) => {
  const notLocated = elem.dataset.located === 'false';
  if (notLocated) {
    elem.setAttribute('data-located', 'true');
  } else {
    elem.setAttribute('data-located', 'false');
  }
};

const toggleLocationMarker = () => {
  const locationInput = document.querySelector('input[name="location-search"]') as HTMLInputElement;
  const selectedPath = document.getElementById('markerPathTwo');
  toggleMarkerColor();
  toggleLocatedAttr(locationInput);

  if (selectedPath?.getAttribute('fill') === TEAL_COLOR) {
    toggleMarkerTitle(true);
    document.getElementById('locationEmptyNote')?.remove();
  } else {
    toggleMarkerTitle(false);
  }
};

const removeLocationMarkerAnimation = () => {
  const locationMarker = document.getElementById('locationMarker') as HTMLButtonElement;

  locationMarker?.classList.remove('animate-bounce');
};

const findGeolocation = (
  defaultLocation?: string | null | undefined,
  userClick?: boolean | undefined,
  toggle: boolean | undefined = true
) => {
  const locationButton = document.getElementById('locationButton') as HTMLButtonElement;
  const locSearch = document.getElementById('locationSearch') as HTMLInputElement;

  if ((defaultLocation !== null && defaultLocation !== undefined) || (defaultLocation !== undefined && !userClick)) {
    locationButton.disabled = false;
    locSearch.disabled = false;
    removeLocationMarkerAnimation();
    toggleLocationMarker();
    return;
  }

  if ('geolocation' in navigator) {
    const loc = navigator.geolocation.getCurrentPosition(
      async (position) => {
        try {
          const coords = position.coords;
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords.latitude},${coords.longitude}&key=AIzaSyBBfD52_0A26J2TP9HPUPH15zmteknwhMQ`
          );
          const data = await response.json();

          const address = data.results.find((r: TGeocodeResult) => r.types.indexOf('postal_code') !== -1);
          const state = address.address_components.find(
            (item: TAddressComponent) =>
              item.types.indexOf('administrative_area_level_1') !== -1 && item.types.indexOf('political') !== -1
          );
          const city = address.address_components.find(
            (item: TAddressComponent) => item.types.indexOf('locality') !== -1 && item.types.indexOf('political') !== -1
          );

          const locationStr = `${city.long_name}, ${state.short_name}`;
          if (locSearch) {
            locSearch.value = locationStr;
            locationButton.disabled = false;
            locSearch.disabled = false;
            removeLocationMarkerAnimation();
            if (toggle) {
              toggleLocationMarker();
            }
          }
        } catch (err) {
          console.error(err);
        }
        return position;
      },
      (err) => {
        locationButton.disabled = false;
        locSearch.disabled = false;
        removeLocationMarkerAnimation();
        console.warn(err || 'User denied Geolocation.');
      }
    );
    return loc;
  } else {
    return 'cannot find geolocation';
  }
};

const formatLocation = (val: string[]): string => `${val[0].replace(/\s/g, '+')}${val[1].replace(/\s/g, '--')}`;

const attachEventListeners = () => {
  const searchBox = document.getElementById('search-box');
  const searchSubmitBtn = document.getElementById('searchSubmitButton');
  const searchCategoryDropdown = document.getElementById('searchCategoryDropdown');
  const searchInput = document.querySelector('input[name="searchInput"]') as HTMLInputElement;
  const locationInput = document.querySelector('input[name="location-search"]') as HTMLInputElement;
  const locationButton = document.getElementById('locationButton') as HTMLButtonElement;
  const locationMarker = document.getElementById('locationMarker') as HTMLElement;
  const locationSearch = document.getElementById('locationSearch') as HTMLInputElement;
  const locationDropdown = document.getElementById('locationDropdown') as HTMLInputElement;
  const fauxContainer = document.getElementById('fauxContainer') as HTMLElement;
  const mobileCancelBtn = document.getElementById('mobileCancelBtn') as HTMLButtonElement;

  const openSEOApp = () => {
    const locationVal = locationInput.value.trim().toLowerCase();
    const searchVal = searchInput.value.trim().toLowerCase();
    let formattedUrl = `${DOMAIN}/jobs`;

    if (locationVal.length > 0) {
      const split = locationVal.split(',');
      formattedUrl = `${formattedUrl}/${formatLocation(split)}`;
    } else if (searchBox && searchBox.dataset.defaultLocation) {
      const split = searchBox.dataset.defaultLocation.split(',');
      formattedUrl = `${formattedUrl}/${formatLocation(split)}`;
    } else {
      // default to chicago
      formattedUrl = `${formattedUrl}/chicago--il`;
    }

    if (searchVal.length > 0) {
      formattedUrl = `${formattedUrl}/${searchVal.replace(/\s/g, '+')}`;
    }
    // concat the utm_source for GA tracking
    // NOTE: this can be refactored to be dynamic based on data-attr
    formattedUrl = `${formattedUrl}?utm_source=wonolo_search_widget`;

    window.open(formattedUrl);
  };

  searchSubmitBtn?.addEventListener('click', () => {
    openSEOApp();
  });

  const locationBtnToggle = () => {
    if (locationSearch.dataset.located === 'false') {
      // repopulate
      locationMarker.classList.add('animate-bounce');
      locationButton.disabled = true;
      findGeolocation(null, true);
    } else {
      // clean up & turn off
      toggleLocationMarker();
    }
  };

  document.onkeydown = function (e) {
    if (e.key === 'Enter') {
      const btn = document?.activeElement as HTMLButtonElement;
      if (btn.getAttribute('id') === 'locationButton') {
        locationBtnToggle();
        return;
      }

      const AEID = document?.activeElement?.getAttribute('id');
      const isEitherInput = AEID === 'searchInput' || AEID === 'locationSearch';
      if (searchInput.value.length > 0 && locationInput.value.length > 0 && isEitherInput) {
        openSEOApp();
        return;
      }
    }
  };

  locationButton?.addEventListener('click', (e) => {
    if (e.x === 0 && e.y === 0) return;
    locationBtnToggle();
  });

  searchInput?.addEventListener('focus', () => {
    if (searchCategoryDropdown) {
      searchCategoryDropdown.style.display = '';
    }
  });

  const toggleMobileSearchView = () => {
    const searchWrapper = document.getElementById('searchWrapper') as HTMLElement;
    const searchJobsText = document.getElementById('searchJobsText') as HTMLElement;
    const magnifyingGlassIcon = document.getElementById('magnifyingGlassIcon');
    const searchSubmitButton = document.getElementById('searchSubmitButton') as HTMLButtonElement;
    const mobileCancelBtn = document.getElementById('mobileCancelBtn') as HTMLButtonElement;

    if (!searchWrapper?.classList.contains('active')) {
      searchWrapper?.classList.add('active');
      searchJobsText?.classList.add('active');
      magnifyingGlassIcon?.classList.add('active');
      searchSubmitButton?.classList.add('active');
      mobileCancelBtn?.classList.add('active');
    } else {
      const searchInput = document.getElementById('searchInput') as HTMLInputElement;
      const locationSearch = document.getElementById('locationSearch') as HTMLInputElement;

      searchInput.value = '';
      locationSearch.value = '';

      searchWrapper?.classList.remove('active');
      searchJobsText?.classList.remove('active');
      magnifyingGlassIcon?.classList.remove('active');
      searchSubmitButton?.classList.remove('active');
      mobileCancelBtn?.classList.remove('active');
    }
  };

  fauxContainer?.addEventListener('click', toggleMobileSearchView);
  mobileCancelBtn?.addEventListener('click', toggleMobileSearchView);

  const closeSearchCategoryDropdown = () => {
    if (searchCategoryDropdown && searchCategoryDropdown.style.display === '') {
      searchCategoryDropdown.style.display = 'none';
    }
  };

  const setSearchCategoryInput = (e: Event) => {
    const searchInput = document.getElementById('searchInput') as HTMLInputElement;
    const target = e.target as HTMLElement;
    if (target.innerText === CATEGORIES_TITLE || target.tagName === 'UL' || target.innerText === NO_MATCH_TEXT) return;
    if (searchInput !== null) {
      searchInput.value = target.innerText;
    }
  };

  const setSearchLocationInput = (e: Event) => {
    const locationInput = document.getElementById('locationSearch') as HTMLInputElement;
    const target = e.target as HTMLElement;

    // bail out to not insert any weird text into input
    if (target.getAttribute('id') === 'locationDropdown') return;

    if (locationInput !== null) {
      if (
        target.getAttribute('id') === 'getCurrentLocationBtn' ||
        target.getAttribute('id') === 'buttonContentContainer'
      ) {
        findGeolocation(null, true, false);
        return;
      }
      locationInput.value = target.innerText;
    }
  };

  const closeLocationDropdown = () => {
    if (locationDropdown && locationDropdown.style.display === '') {
      locationDropdown.style.display = 'none';
    }
  };

  // close dropdowns
  document.addEventListener('click', () => {
    const activeId = document.activeElement?.getAttribute('id');
    if (activeId !== 'searchInput' && activeId !== 'searchCategoryDropdown') {
      closeSearchCategoryDropdown();
    }
    if (activeId !== 'locationSearch' && activeId !== 'locationDropdown') {
      closeLocationDropdown();
    }
  });

  document.addEventListener('keydown', () => {
    const activeId = document.activeElement?.getAttribute('id');
    if (
      activeId !== 'searchInput' &&
      activeId !== 'searchCategoryDropdown' &&
      !document.activeElement?.classList.contains('category-list-item')
    ) {
      closeSearchCategoryDropdown();
    }
    if (
      activeId !== 'locationSearch' &&
      activeId !== 'locationDropdown' &&
      !document.activeElement?.classList.contains('location-list-item')
    ) {
      closeLocationDropdown();
    }
  });

  searchCategoryDropdown?.addEventListener('keydown', (e) => {
    if (e.key === 'Enter') {
      closeSearchCategoryDropdown();
      setSearchCategoryInput(e);
    }
    if (e.key === 'Escape') {
      closeSearchCategoryDropdown();
    }
  });

  locationDropdown?.addEventListener('keydown', (e) => {
    if (e.key === 'Enter') {
      closeLocationDropdown();
      setSearchLocationInput(e);
    }
    if (e.key === 'Escape') {
      closeLocationDropdown();
    }
  });

  searchCategoryDropdown?.addEventListener('click', (e: Event) => {
    setSearchCategoryInput(e);
  });

  locationDropdown?.addEventListener('click', (e: Event) => {
    setSearchLocationInput(e);
  });

  if (searchInput) {
    searchInput.oninput = () => {
      let hiddenCounter = 0;

      if (searchCategoryDropdown?.children) {
        for (let i = 0; i < searchCategoryDropdown.children.length; i += 1) {
          const child = searchCategoryDropdown?.children[i] as HTMLLIElement;
          const liContent = child.textContent?.toLowerCase() || child?.innerText?.toLowerCase();
          if (child.innerText === CATEGORIES_TITLE || child.innerText === NO_MATCH_TEXT) continue;
          if (liContent.toLowerCase().includes(searchInput.value.toLowerCase())) {
            child.style.display = '';
          } else {
            child.style.display = 'none';
            hiddenCounter += 1;
          }
        }
      }

      // add or remove empty message
      if (hiddenCounter >= VALID_CATEGORIES.length && document.getElementById('emptyMessage') === null) {
        const emptyTextResult = document.createElement('li');
        emptyTextResult.innerText = NO_MATCH_TEXT;
        emptyTextResult.setAttribute('id', 'emptyMessage');
        searchCategoryDropdown?.appendChild(emptyTextResult);
      } else if (hiddenCounter < VALID_CATEGORIES.length) {
        document.getElementById('emptyMessage')?.remove();
      }
    };
  }

  locationSearch?.addEventListener('focus', () => {
    if (locationDropdown) {
      locationDropdown.style.display = '';
    }
  });

  // gets initialized/set onfocus of locationSearch
  // and not on initialization of the file
  // to ensure google is available, see below
  let acs: google.maps.places.AutocompleteService;

  locationSearch.addEventListener(
    'keyup',
    debounce(async () => {
      const selectedPath = document.getElementById('markerPathTwo');
      if (locationSearch.value.length === 0) {
        locationDropdown.innerHTML = '';
        locationDropdown.appendChild(generateGetCurrentLocationBtn());
        return;
      }

      // don't run predictions if location is turned off
      if (selectedPath?.getAttribute('fill') === GREY_COLOR) return;

      const preds = await acs?.getPlacePredictions({
        input: locationSearch.value,
        types: ['(cities)'],
        componentRestrictions: { country: 'us' },
      });

      if (preds !== undefined && preds.predictions?.length > 0) {
        locationDropdown.innerHTML = '';
        preds.predictions.map((item: google.maps.places.AutocompletePrediction) => {
          const itemContent = item.description.replace(', USA', '');
          const predLI = document.createElement('li');
          predLI.innerText = itemContent;
          predLI.className = 'location-list-item';
          predLI.ariaRoleDescription = 'list';
          predLI.tabIndex = 0;
          locationDropdown.appendChild(predLI);
          return item;
        });
      } else {
        locationDropdown.innerHTML = '';
        locationDropdown.appendChild(generateGetCurrentLocationBtn());
        locationDropdown.appendChild(generateLocationEmptyNote());
      }
    }, 500)
  );
  if (locationSearch) {
    locationSearch.onfocus = () => {
      acs = new google.maps.places.AutocompleteService();
    };
  }
};

export { attachEventListeners, findGeolocation };
