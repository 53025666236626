export const debounce = (callback: (e: Event) => void, timeout: number) => {
  let timer: NodeJS.Timeout | undefined;
  return (e: Event) => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => callback.apply(this, [e]), timeout);
  };
};

export const phoneNumberFormatter = (phoneNumber: string) => {
  // Strip all characters from the input except digits
  const val = phoneNumber.replace(/\D/g, '').substring(0, 10);

  const len = val.length;

  if (len > 3 && len < 7) {
    return `${val.substring(0, 3)}-${val.substring(3, 6)}`;
  }

  if (len > 6) {
    return `${val.substring(0, 3)}-${val.substring(3, 6)}-${val.substring(6, 10)}`;
  }

  return val;
};

export const getCookieName = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
  return;
};
