import { GRADIENT_CHECK_SUCCESS, LINKS_MAP, PHONE_IMAGE } from '@widgets-helpers';
import { attachEventListenersTMLUI } from '@widgets-ui';

const TextMeLinkBox = document.getElementById('text-me-box');
const isWordpressTheme = TextMeLinkBox?.dataset?.type === 'wordpress';

export const generateTextMeContent = () => {
  require('@widgets-styles/tw-import.css');
  isWordpressTheme
    ? require('@widgets-styles/TextMeLink/TextMeLinkWP.css')
    : require('@widgets-styles/TextMeLink/TextMeLinkSEO.css');
  const contentContainer = document.createElement('div');
  // this works a tad bit different, therefore the ID convention is different
  const TextMeLinkBox = document.getElementById('text-me-box');
  const contentTitleContainer = generateTextMeTitle();
  const userFeedbackContainer = generateUserFeedbackContainer();
  const mobileInputField = generateTextMeInput();
  const textMeLinkButton = generateTextMeButton();
  const disclaimerContent = generateDisclaimerContent();
  const successScreen = generateSuccessScreen();

  contentContainer.setAttribute('id', 'contentContainer');

  contentContainer.appendChild(contentTitleContainer);
  contentContainer.appendChild(mobileInputField);
  contentContainer.appendChild(userFeedbackContainer);
  contentContainer.appendChild(textMeLinkButton);
  contentContainer.appendChild(disclaimerContent);

  TextMeLinkBox?.appendChild(successScreen);
  TextMeLinkBox?.appendChild(contentContainer);

  return contentContainer;
};

export const generateTextMeTitle = () => {
  const contentTitleContainer = document.createElement('h2');
  contentTitleContainer.setAttribute('id', 'contentTitleContainer');
  contentTitleContainer.innerHTML = 'Start Wonoloing!';
  return contentTitleContainer;
};

export const generateTextMeInput = () => {
  const labelContainer = document.createElement('label');
  const imgContainer = document.createElement('div');
  const phoneImg = document.createElement('img');
  const mobileInput = document.createElement('input');

  labelContainer.setAttribute('id', 'labelContainer');
  mobileInput.setAttribute('id', 'mobileInput');
  phoneImg.setAttribute('id', 'phoneImage');

  phoneImg.src = PHONE_IMAGE;
  phoneImg.width = 20;
  phoneImg.height = 20;
  phoneImg.alt = 'Enter Mobile Phone';

  mobileInput.placeholder = 'Enter Mobile Number';
  mobileInput.maxLength = 12;
  mobileInput.minLength = 4;
  mobileInput.required = true;

  imgContainer.appendChild(phoneImg);
  labelContainer.appendChild(imgContainer);
  labelContainer.appendChild(mobileInput);

  return labelContainer;
};

export const generateUserFeedbackContainer = () => {
  const userFeedbackContainer = document.createElement('p');

  userFeedbackContainer.setAttribute('id', 'userFeedbackContainer');
  userFeedbackContainer.classList.add('hidden');

  return userFeedbackContainer;
};

export const generateTextMeButton = () => {
  const textMeButton = document.createElement('button');

  textMeButton.setAttribute('id', 'textMeButton');
  textMeButton.innerText = 'Text me the link!';
  textMeButton.disabled = true;

  return textMeButton;
};

export const generateDisclaimerContent = () => {
  const disclaimerContainer = document.createElement('p');
  const termsOfUse = `<a class="disclaimer-links" target="_blank" href='${LINKS_MAP.terms}'>Terms of Use</a>`;
  const privacyPolicy = `<a class="disclaimer-links" target="_blank" href='${LINKS_MAP.privacy}'>Privacy</a>`;
  const acceptablePolicy = `<a class="disclaimer-links" target="_blank" href='${LINKS_MAP.policy}'>Acceptable Use</a>`;

  disclaimerContainer.setAttribute('id', 'disclaimerContainer');
  disclaimerContainer.innerHTML = `By submitting your mobile number, you consent to Wonolo's ${termsOfUse}, ${privacyPolicy}, and ${acceptablePolicy} policies. Standard message rates may apply.`;

  return disclaimerContainer;
};

export const generateSuccessScreen = () => {
  const screenContainer = document.createElement('div');
  const imageContainer = document.createElement('div');
  const successImage = document.createElement('img');
  const textContainer = document.createElement('p');

  screenContainer.setAttribute('id', 'successContainer');
  imageContainer.setAttribute('id', 'imageContainer');
  textContainer.setAttribute('id', 'successMessage');
  successImage.setAttribute('id', 'successImage');

  screenContainer.classList.add('!hidden');

  successImage.src = GRADIENT_CHECK_SUCCESS;

  imageContainer.appendChild(successImage);
  screenContainer.appendChild(imageContainer);
  screenContainer.appendChild(textContainer);

  return screenContainer;
};

export const generateMobileIntake = () => {
  generateTextMeContent();
  attachEventListenersTMLUI();
};

const script = document.getElementById('mobile-intake-core-bundle');

if (script !== null) {
  script.onload = () => {
    generateMobileIntake();
  };
}
