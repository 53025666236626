import { findGeolocation, attachEventListeners } from '@widgets-ui';
import { VALID_CATEGORIES, CATEGORIES_TITLE, GOOGLE_PLACES_API, TEAL_COLOR } from '@widgets-helpers';
import { TLocationDropdownItems } from '@widgets-types';
require('@widgets-styles/tw-import.css');

// entry point
const SearchBox = document.getElementById('search-box');
const cityMarketDefaultLocation = SearchBox?.dataset.defaultLocation || '';

require('@widgets-styles/SearchBox/SearchBoxDefault.css');

export const generateSearchWrapper = () => {
  const searchWrapper = document.createElement('div');
  searchWrapper.setAttribute('id', 'searchWrapper');
  return searchWrapper;
};

export const generateSearchInput = () => {
  const searchContainer = document.createElement('div');
  const innerSearchContainer = document.createElement('div');
  const inputContainer = document.createElement('div');
  const input = document.createElement('input');

  input.type = 'text';
  input.name = 'job-search';
  input.placeholder = 'All Jobs';

  searchContainer.setAttribute('id', 'searchContainer');
  innerSearchContainer.setAttribute('id', 'innerSearchContainer');
  inputContainer.setAttribute('id', 'inputContainer');
  input.setAttribute('id', 'searchInput');
  input.setAttribute('name', 'searchInput');

  inputContainer.appendChild(input);
  innerSearchContainer.appendChild(inputContainer);
  innerSearchContainer.appendChild(generateSearchCategoryDropdown());
  searchContainer.appendChild(innerSearchContainer);

  return searchContainer;
};

export const generateMobileCancelBtn = () => {
  const textBtn = document.createElement('button');
  textBtn.setAttribute('id', 'mobileCancelBtn');
  textBtn.innerText = 'Cancel';
  return textBtn;
};

export const generateMobileFauxInput = () => {
  const fauxInputContainer = document.createElement('div');
  const categoryText = document.createElement('p');
  const locationText = document.createElement('p');

  fauxInputContainer.setAttribute('id', 'fauxContainer');
  categoryText.setAttribute('id', 'categoryText');
  locationText.setAttribute('id', 'locationText');
  categoryText.className = 'text-sm font-semibold text-black';
  locationText.className = 'text-xs font-normal text-brandDarkGray';
  categoryText.innerText = 'All Jobs';
  locationText.innerText = 'Anywhere';

  fauxInputContainer.appendChild(categoryText);
  fauxInputContainer.appendChild(locationText);

  return fauxInputContainer;
};

export const generateDivider = () => {
  const divider = document.createElement('div');

  divider.setAttribute('id', 'inputDivider');

  return divider;
};

export const generateLocationContainer = () => {
  const container = document.createElement('div');
  const insideContainer = document.createElement('div');
  const locInputContainer = document.createElement('div');
  const locationBtn = document.createElement('button');

  locationBtn.disabled = true;
  locationBtn.type = 'button';

  container.setAttribute('id', 'locationContainer');
  insideContainer.setAttribute('id', 'locationInsideContainer');
  locInputContainer.setAttribute('id', 'locationInputContainer');
  locationBtn.setAttribute('id', 'locationButton');

  container.appendChild(insideContainer);
  locationBtn.appendChild(generateLocationMarkerSvg());
  locInputContainer.appendChild(locationBtn);
  locInputContainer.appendChild(generateSearchLocationInput());
  locInputContainer.appendChild(generateSearchLocationDropdown());
  insideContainer.appendChild(locInputContainer);

  return container;
};

export const generateLocationMarkerSvg = () => {
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  const title = document.createElementNS('http://www.w3.org/2000/svg', 'title');
  const gElem = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  const pathOne = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  const pathTwo = document.createElementNS('http://www.w3.org/2000/svg', 'path');

  title.innerHTML = 'Disable Location Services';
  svg.classList.add('animate-bounce');

  svg.setAttribute('id', 'locationMarker');
  svg.setAttribute('width', '20px');
  svg.setAttribute('height', '20px');
  svg.setAttribute('viewBox', '0 0 21 20');
  svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

  title.setAttribute('id', 'locationMarkerTitle');

  gElem.setAttribute('fillRule', 'evenodd');

  pathOne.setAttribute('fill', 'none');
  pathOne.setAttribute('d', 'M.667 0h20v20h-20z');
  pathTwo.setAttribute('id', 'markerPathTwo');
  pathTwo.setAttribute(
    'd',
    'M10.996 19.66a.816.816 0 0 1-1.325 0c-1.157-1.62-6.004-8.668-6.004-12.993a6.667 6.667 0 0 1 13.333 0c0 4.325-4.847 11.373-6.004 12.993zM10.333 2.5a4.167 4.167 0 1 0 0 8.333 4.167 4.167 0 0 0 0-8.333z'
  );
  pathTwo.setAttribute('fill', '#a0a0a0');
  pathTwo.setAttribute('fillRule', 'nonzero');

  gElem.appendChild(pathOne);
  gElem.appendChild(pathTwo);
  svg.appendChild(gElem);
  svg.appendChild(title);

  return svg;
};

export const generateMagnifyingGlassIconSvg = () => {
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  const title = document.createElementNS('http://www.w3.org/2000/svg', 'title');
  const pathOne = document.createElementNS('http://www.w3.org/2000/svg', 'path');

  title.innerHTML = 'Search';

  svg.setAttribute('id', 'magnifyingGlassIcon');
  svg.setAttribute('width', '1.5em');
  svg.setAttribute('height', '1.5em');
  svg.setAttribute('viewBox', '0 0 24 24');
  svg.setAttribute('focusable', 'false');
  svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

  title.setAttribute('id', 'magnifyingGlassTitle');

  pathOne.setAttribute('fill', '#FFFFFF');
  pathOne.setAttribute(
    'd',
    'M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'
  );

  svg.appendChild(title);
  svg.appendChild(pathOne);

  return svg;
};

export const generateLocationTriangleIconSvg = () => {
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  const title = document.createElementNS('http://www.w3.org/2000/svg', 'title');
  const pathOne = document.createElementNS('http://www.w3.org/2000/svg', 'path');

  title.innerHTML = 'Get Current Location';

  svg.setAttribute('id', 'locationTriangleIcon');
  svg.setAttribute('width', '16px');
  svg.setAttribute('height', '16px');
  svg.setAttribute('viewBox', '0 0 24 24');
  svg.setAttribute('fill', 'none');
  svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

  title.setAttribute('id', 'locationTriangleIconTitle');

  pathOne.setAttribute('fill', TEAL_COLOR);
  pathOne.setAttribute(
    'd',
    'M15.4064 22.8525C15.0857 23.6717 14.2563 24.1333 13.427 23.9659C12.5976 23.7929 11.9507 23.0371 11.9507 22.1544V12.0003H2.21974C1.42412 12.0003 0.696509 11.3772 0.533404 10.5118C0.3703 9.64644 0.817041 8.78103 1.59939 8.44641L21.0625 0.137404C21.7205 -0.14414 22.4779 0.0150937 22.9811 0.540681C23.4842 1.06685 23.639 1.85725 23.3681 2.54553L15.4064 22.8525Z'
  );

  svg.appendChild(title);
  svg.appendChild(pathOne);

  return svg;
};

export const generateSearchLocationInput = () => {
  const input = document.createElement('input');

  input.setAttribute('id', 'locationSearch');

  input.type = 'text';
  input.disabled = true;
  input.setAttribute('data-located', 'false');
  input.name = 'location-search';
  input.placeholder = 'Chicago, IL';
  input.value = cityMarketDefaultLocation ?? '';

  return input;
};

export const generateSearchSubmitBtn = () => {
  const btn = document.createElement('button');

  btn.ariaLabel = 'Search Button';

  btn.setAttribute('id', 'searchSubmitButton');

  btn.appendChild(generateMagnifyingGlassIconSvg());
  btn.appendChild(generateSearchJobsText());

  return btn;
};

export const generateSearchJobsText = () => {
  const text = document.createElement('span');
  text.setAttribute('id', 'searchJobsText');
  text.innerText = 'Search Jobs';
  return text;
};

export const generateSearchMagnifyingGlass = () => {
  const image = document.createElement('img');

  image.src = '';
  image.alt = 'Search';
  image.width = 24;
  image.height = 24;

  return image;
};

export const generateSearchCategoryDropdown = () => {
  const ddContainer = document.createElement('ul');
  const titleListItem = document.createElement('li') as HTMLLIElement;

  ddContainer.setAttribute('id', 'searchCategoryDropdown');
  titleListItem.setAttribute('id', 'titleListItem');

  ddContainer.ariaRoleDescription = 'list';
  ddContainer.tabIndex = 0;
  ddContainer.style.display = 'none';
  titleListItem.innerText = CATEGORIES_TITLE;

  ddContainer.appendChild(titleListItem);

  VALID_CATEGORIES.map((item, index) => {
    const categoryListItem = document.createElement('li');

    categoryListItem.className = 'category-list-item';
    categoryListItem.innerText = item;
    categoryListItem.ariaRoleDescription = 'listitem';
    categoryListItem.ariaLabel = `category-menuitem-${item}-${index + 1}`;
    categoryListItem.tabIndex = 0;
    categoryListItem.setAttribute('id', `${item.toLocaleLowerCase().replace(/\s/g, '')}ListItem`);
    ddContainer.appendChild(categoryListItem);
    return item;
  });

  return ddContainer;
};

export const generateLocationEmptyNote = () => {
  const locEmptyNote = document.createElement('div');
  locEmptyNote.innerText = 'No results.';
  locEmptyNote.setAttribute('id', 'locationEmptyNote');
  return locEmptyNote;
};

export const generateGetCurrentLocationBtn = () => {
  const getCurrentLocationBtn = document.createElement('button');
  const container = document.createElement('div');
  const text = document.createElement('span');

  container.setAttribute('id', 'buttonContentContainer');

  text.setAttribute('id', 'getLocationText');
  text.innerText = 'Get Current Location';

  container.appendChild(generateLocationTriangleIconSvg());
  container.appendChild(text);
  getCurrentLocationBtn.appendChild(container);

  getCurrentLocationBtn.setAttribute('id', 'getCurrentLocationBtn');
  getCurrentLocationBtn.ariaRoleDescription = 'button';
  getCurrentLocationBtn.tabIndex = 0;
  getCurrentLocationBtn.className = 'location-list-item';

  return getCurrentLocationBtn;
};

export const generateSearchLocationDropdown = (items?: TLocationDropdownItems) => {
  const locDDContainer = document.createElement('ul');

  locDDContainer.setAttribute('id', 'locationDropdown');
  locDDContainer.tabIndex = 0;
  locDDContainer.ariaRoleDescription = 'list';
  locDDContainer.style.display = 'none';

  if (items && items.length > 0) {
    items?.map((item) => {
      const locItem = document.createElement('li');
      const itemContent = item?.description.replace(', USA', '');
      locItem.innerText = itemContent;
      locItem.className = 'location-list-item';
      locItem.ariaRoleDescription = 'list';
      locItem.tabIndex = 0;
      locDDContainer.appendChild(locItem);
      return locItem;
    });
  } else {
    locDDContainer.appendChild(generateGetCurrentLocationBtn());
  }

  return locDDContainer;
};

const generateGoogleAPIScript = () => {
  const script = document.createElement('script');
  script.src = GOOGLE_PLACES_API;
  document.body.appendChild(script);
  return script;
};

export const generateSearch = () => {
  const wrapper = generateSearchWrapper();
  // get google lib
  generateGoogleAPIScript();
  // generate search form
  wrapper.appendChild(generateMobileCancelBtn());
  wrapper?.appendChild(generateSearchInput());
  wrapper?.appendChild(generateDivider());
  wrapper?.appendChild(generateLocationContainer());
  SearchBox?.appendChild(generateMobileFauxInput());
  SearchBox?.appendChild(wrapper);
  SearchBox?.appendChild(generateSearchSubmitBtn());
  attachEventListeners();
  findGeolocation(cityMarketDefaultLocation);
};

const script = document.getElementById('search-box-bundle');

if (script !== null) {
  script.onload = () => {
    generateSearch();
  };
}
