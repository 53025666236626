export const VALID_CATEGORIES = [
  'Delivery',
  'General Laborer',
  'Merchandising',
  'Food Production',
  'Manufacturing/Production',
  'Washing & Cleaning',
  'Fulfillment and Warehousing',
  'Administrative',
  'Event Staff',
  'Customer Service',
  'Brand Ambassador',
  'Other',
  'Permanent Position',
  'Market Research',
  'Security',
  'Data Entry',
  'Sales',
  'Valet / Parking',
  'Ready-to-Work',
  'Marketing',
  'Warehousing',
  'Software Testing',
  'Español (Spanish only)',
  'Stocking',
  'Volunteer',
  'Call Center',
  'Driving & Delivery',
].sort();

export const LINKS_MAP = {
  terms: 'https://www.wonolo.com/customer-agreement',
  privacy: 'https://www.wonolo.com/privacy/',
  policy: 'https://www.wonolo.com/acceptable-use-policy',
};

export const CATEGORIES_TITLE = 'Categories';
export const TEAL_COLOR = '#136f71';
export const GREY_COLOR = '#a0a0a0';
export const NO_MATCH_TEXT = "Can't find matching category.";
// these urls comes from the CDN of prod jobs netlify
export const GRADIENT_CHECK_SUCCESS =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8ZGVmcz4KICAgICAgICA8bGluZWFyR3JhZGllbnQgeDE9Ii0xNS4wOCUiIHkxPSItMjguNTY0JSIgeDI9IjEwMCUiIHkyPSIxMDAlIiBpZD0iYSI+CiAgICAgICAgICAgIDxzdG9wIHN0b3AtY29sb3I9IiMzNEE1QTciIG9mZnNldD0iMCUiLz4KICAgICAgICAgICAgPHN0b3Agc3RvcC1jb2xvcj0iI0FCRTc3NSIgb2Zmc2V0PSI5OS45MjYlIi8+CiAgICAgICAgPC9saW5lYXJHcmFkaWVudD4KICAgIDwvZGVmcz4KICAgIDxwYXRoIGQ9Ik0xNjAgNzJjLTEzLjI1NSAwLTI0LTEwLjc0NS0yNC0yNHMxMC43NDUtMjQgMjQtMjQgMjQgMTAuNzQ1IDI0IDI0LTEwLjc0NSAyNC0yNCAyNHptMTMuNC0zNS40MTlhMi4wMDEgMi4wMDEgMCAwIDAtMy4wNDkuMjc4bC0xMi44NDQgMTguMzktNS44NzQtOC40MTFhMi4wMDEgMi4wMDEgMCAwIDAtMy4wNS0uMjc4IDIuMDg1IDIuMDg1IDAgMCAwLS4xNyAyLjY3bDYuNjI0IDkuNDg1YTMuMDE0IDMuMDE0IDAgMCAwIDQuOTQgMGwxMy41OTQtMTkuNDY0YTIuMDg1IDIuMDg1IDAgMCAwLS4xNzEtMi42N3oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMzYgLTI0KSIgZmlsbD0idXJsKCNhKSIgZmlsbC1ydWxlPSJub256ZXJvIi8+Cjwvc3ZnPg==';
export const PHONE_IMAGE =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGNsYXNzPSJoLTYgdy02IiBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0cm9rZT0iI2RkZCIgc3Ryb2tlLXdpZHRoPSIyIj4KICA8cGF0aCBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGQ9Ik0xMiAxOGguMDFNOCAyMWg4YTIgMiAwIDAwMi0yVjVhMiAyIDAgMDAtMi0ySDhhMiAyIDAgMDAtMiAydjE0YTIgMiAwIDAwMiAyeiIgLz4KPC9zdmc+';
export const GOOGLE_PLACES_API =
  'https://maps.googleapis.com/maps/api/js?key=AIzaSyBBfD52_0A26J2TP9HPUPH15zmteknwhMQ&libraries=places';
export const CLOSE_X_IMAGE =
  'https://jobs.wonolo.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fclose-icon.aad6014a.webp&w=256&q=75';
export const SEO_APP_DOMAIN = 'https://jobs.wonolo.com';
