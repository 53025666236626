import { generateMobileIntake } from '@widgets-components';
const sidebar = document.getElementById('sidebar-mobile');

export const generateSidebarContainer = () => {
  require('@widgets-styles/TextMeLink/TextMeLinkSidebar.css');
  const sideBarContainer = document.createElement('div');
  sideBarContainer.setAttribute('id', 'text-me-box');
  sidebar?.appendChild(sideBarContainer);
  generateMobileIntake();
  return sideBarContainer;
};

const script = document.getElementById('mobile-intake-sidebar-bundle');

if (script !== null) {
  script.onload = () => {
    generateSidebarContainer();
  };
}
